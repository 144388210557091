import { computed, defineComponent, onBeforeUnmount, ref, unref, onMounted, watchEffect, } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { MobileRegexp, PassRegexp, loginValidate, } from "@/hooks/component/loginValidate";
import { sendLoginVerifyCode } from "@/apis";
import { trim } from "@/utils";
import { globalStorage } from "@/utils/storage/base";
export default defineComponent({
    name: "AuthLogin",
    components: {},
    setup() {
        const { currentRoute, push } = useRouter();
        const store = useStore();
        const isShow = ref(true);
        // 登录方式 password: 密码登录 code: 验证码登录
        const loginType = ref("password");
        // 注册过来的弹窗状态
        const regSuccessTipVisible = ref(false);
        const tipDisabled = ref(false);
        // 弹窗剩余时间
        const durTime = ref(3);
        // form表单
        const { form, handleLoginCorps, initAwsc } = loginValidate();
        // 标题
        const title = computed(() => loginType.value === "password" ? "欢迎使用氚云" : "验证码登录");
        // 密码登录按钮是否点击
        const pwdDisabled = computed(() => form.mobile !== "" &&
            form.password !== "" &&
            loginType.value === "password");
        // 密码登录按钮样式
        const pwdBtnType = computed(() => pwdDisabled.value && loginType.value === "password"
            ? "primary"
            : "default");
        // 验证码按钮是否点击
        const codeDisabled = computed(() => form.mobile !== "" && loginType.value === "code");
        // 验证码按钮样式
        const codeBtnType = computed(() => codeDisabled.value && loginType.value === "code" ? "primary" : "default");
        // 注册后的engine码
        const enginecode = computed(() => unref(currentRoute).params.enginecode ?? "");
        // 手机号
        const tipMobile = computed(() => unref(currentRoute).query.mobile ?? "");
        const from = computed(() => unref(currentRoute).query.from ?? "");
        // 微信code码
        const wechatcode = globalStorage.getFormLocal("login_wechat_code") ?? '';
        form.wechatcode = wechatcode;
        let timer = null;
        let isSend = false;
        // 跳转验证码
        function jumpIdentifyCode() {
            push({
                name: "AuthIdentifyCode",
                query: {
                    mobile: trim(form.mobile),
                    from: "login",
                    wechatcode: form.wechatcode
                },
            });
        }
        // 跳转微信登录
        function jumpLoginWechat() {
            push({
                name: "AuthWechat",
                query: {
                    from: "wechat",
                },
            });
        }
        // 发送登录验证码
        function sendLoginVerify(params) {
            sendLoginVerifyCode({
                mobile: trim(form.mobile),
                ...params,
            }).then((res) => {
                isShow.value = true;
                if (res.errcode === 0) {
                    Toast("验证码发送成功\n请注意手机短信");
                    // 设置持续存储timer
                    globalStorage.setToLocal("auto_login_timer", "60");
                    setTimeout(() => {
                        jumpIdentifyCode();
                    }, 500);
                }
                else if (res.errcode === 202400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    Toast(res.errmsg || "系统异常");
                    handleInitAwsc();
                }
                isSend = false;
            });
        }
        // 发送验证码-获取人机验证码
        function sendLoginCaptcha() {
            if (isSend)
                return;
            if (!MobileRegexp.test(trim(form.mobile))) {
                Toast("请填写正确的手机号码");
                return;
            }
            window.nvc.getNVCValAsync((nvcVal) => {
                isSend = true;
                sendLoginVerify({ captcha: nvcVal });
            });
        }
        // 密码登录
        function loginWithPass(params) {
            store
                .dispatch("loginWithPassAction", {
                mobile: form.mobile,
                password: form.password,
                wechatcode: form.wechatcode,
                ...params,
            })
                .then((res) => {
                if (res.errcode === 0) {
                    regSuccessTipVisible.value = false;
                    handleLoginCorps(res);
                }
                else if (res.errcode === 202400) {
                    isShow.value = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    isShow.value = true;
                    Toast(res.errmsg);
                    handleInitAwsc();
                }
            })
                .catch((errmsg) => {
                Toast(errmsg);
            });
        }
        // 密码登录-获取人机验证码
        function handleSubmit() {
            if (!MobileRegexp.test(trim(form.mobile))) {
                Toast("请填写正确的手机号码");
                return;
            }
            if (!PassRegexp.test(form.password)) {
                Toast("密码6-16位含数字和字母");
                return;
            }
            window.nvc.getNVCValAsync((nvcVal) => {
                loginWithPass({ captcha: nvcVal });
            });
        }
        // 通过注册过来的开始使用弹窗
        function showModal() {
            durTime.value = 3;
            regSuccessTipVisible.value = true;
            tipDisabled.value = false;
            timer = setInterval(() => {
                durTime.value--;
                if (durTime.value <= 0) {
                    clearInterval(timer);
                    timer = null;
                    tipDisabled.value = true;
                }
            }, 1000);
        }
        // 通过注册过来的登录
        function handleLoginByRegister() {
            form.mobile = tipMobile.value;
            regSuccessTipVisible.value = false;
        }
        // 跳转忘记密码
        function jumpForgetPassword() {
            push({
                name: "AuthPasswordReset",
                query: {
                    from: "login",
                    wechatcode: form.wechatcode
                },
            });
        }
        // 跳转注册
        function jumpRegister() {
            push({
                name: "AuthRegister",
                query: {
                    mobile: form.mobile,
                    from: "login",
                    wechatcode: form.wechatcode
                },
            });
        }
        // 改变登录方式
        function changeLoginType() {
            isShow.value = true;
            loginType.value = loginType.value === "password" ? "code" : "password";
        }
        // 初始化
        function handleInitAwsc() {
            if (loginType.value === "password") {
                initAwsc('login', loginWithPass);
            }
            else if (loginType.value === "code") {
                initAwsc('login', sendLoginVerify);
            }
        }
        // 立即执行
        watchEffect(() => {
            handleInitAwsc();
        });
        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
        });
        onMounted(() => {
            // 判断你是否注册成功
            if (enginecode.value && from.value === "register") {
                showModal();
            }
            form.mobile = tipMobile.value ?? "";
        });
        return {
            isShow,
            loginType,
            title,
            regSuccessTipVisible,
            form,
            pwdDisabled,
            pwdBtnType,
            codeDisabled,
            codeBtnType,
            durTime,
            tipDisabled,
            tipMobile,
            handleSubmit,
            sendLoginCaptcha,
            showModal,
            handleLoginByRegister,
            changeLoginType,
            jumpForgetPassword,
            jumpLoginWechat,
            jumpRegister,
            jumpIdentifyCode,
        };
    },
});
